import React, { useState, useEffect } from "react";
import "./Uncategorized";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import deleteicon from '../../assets/icons/delete2.png';
import archive from '../../assets/icons/archive.png';

import edit from '../../assets/icons/edit-icon.png';
import { useParams, useNavigate } from "react-router-dom";

export default function OnePhoto(props) {

    const navigate = useNavigate();

    const [items, setItems] = useState([]);

    const changeState = 'https://apiphotosapp62bab4473780b.cloud.bunnyroute.com/photos/' + useParams().id;

    const photo_name = useParams().id;

    let nr_photo = useParams().number;

    const idAlbum = useParams().id_album;

    const albumName = useParams().album;

    const [photos, setPhotos] = useState(false);

    const category = window.location.pathname.split('/')[1];

    const notify = (a) => toast(<Notif name={a} />);

    function Notif(props) {
        return <div>Moved to <strong>{props.name} </strong> <br />{photo_name.length > 30 ? `${photo_name.substring(0, 25)}...` : photo_name}</div>;
    }

    function filenameTrim(value) {
        if (value != "") {
            let result = value.replace('https://apiphotosapp62bab4473780b.cloud.bunnyroute.com/photos/', '');
            return result
        }
    }

    function getPhotos() {
        // console.log(idAlbum == 'null');
        if (albumName == 'All website photos') {
            axios.get(process.env.REACT_APP_API_URL + `/api/get-photos.php?type=${category}`).then((response) => {
                let apiData = response.data;
                setPhotos(apiData);
            })
        } else {

            axios.get(process.env.REACT_APP_API_URL + `/api/get-photos.php?type=${category}&id_album=${idAlbum}`).then((response) => {
                let apiData = response.data;
                setPhotos(apiData);
            })
        }
    }

    function trashPhoto(value, type) {
        let name = filenameTrim(value);
        let album = idAlbum;
        let index_current_photo = photos.findIndex((el) => {
            return el.filename == photo_name;
        });

        if (category == "uncategorized") {
            if (photos.length > 1) {
                let album = 0;
                if (index_current_photo + 1 < nr_photo) {
                    axios.post(process.env.REACT_APP_API_URL + '/api/move-photo.php', { filename: name, photo_title: name, photo_category: type, id_album: album, from: category }).then((response) => {
                        let apiData = response.status;
                        notify(type);
                        setTimeout(function () {
                            window.location.replace(`/${category}/${nr_photo - 1}/${photos[index_current_photo + 1].filename}`);
                        }, 2000);
                    })
                } else {
                    axios.post(process.env.REACT_APP_API_URL + '/api/move-photo.php', { filename: name, photo_title: name, photo_category: type, id_album: album, from: category }).then((response) => {
                        let apiData = response.status;
                        notify(type);
                        setTimeout(function () {
                            window.location.replace(`/${category}/${nr_photo - 1}/${photos[index_current_photo - 1].filename}`);
                        }, 2000);
                    })
                }
            } else {
                axios.post(process.env.REACT_APP_API_URL + '/api/move-photo.php', { filename: name, photo_title: name, photo_category: type, id_album: album, from: category }).then((response) => {
                    let apiData = response.status;
                    notify(type);
                    setTimeout(function () {
                        window.location.replace(`/${category}`);
                    }, 2000);
                })
            }
        } else if (category == "website") {
            if (photos.length > 1) {
                nr_photo = photos.length;
                const index_current_photo = photos.findIndex((el) => {
                    return el.filename == photo_name;
                })
                if (index_current_photo + 1 < nr_photo) {
                    axios.post(process.env.REACT_APP_API_URL + '/api/move-photo.php', { filename: name, photo_title: name, photo_category: type, id_album: album, from: category }).then((response) => {
                        let apiData = response.status;
                    })
                    notify(type);
                    setTimeout(function () {
                        window.location.replace(`/${category}/${albumName}/${album}/${photos[index_current_photo + 1].filename}`);
                    }, 2000);
                } else {
                    axios.post(process.env.REACT_APP_API_URL + '/api/move-photo.php', { filename: name, photo_title: name, photo_category: type, id_album: album, from: category }).then((response) => {
                        let apiData = response.status;

                    })
                    notify(type);
                    setTimeout(function () {
                        window.location.replace(`/${category}/${albumName}/${album}/${photos[0].filename}`);
                    }, 2000);
                }
            } else if (photos.length == 1) {
                axios.post(process.env.REACT_APP_API_URL + '/api/move-photo.php', { filename: name, photo_title: name, photo_category: type, id_album: album, from: category }).then((response) => {
                    let apiData = response.status;
                    notify(type);
                    setTimeout(function () {
                        window.location.replace(`/${category}/all`);
                    }, 2000);
                }
                )
            }
        }
    }

    // Renaming a photo

    const [customName, setCustomName] = useState();

    const photoName = useParams().id;

    const [name, setName] = useState(photoName);

    function changeName(value) {
        // console.log("newname");
        axios.post(process.env.REACT_APP_API_URL + '/api/photo-name.php', { filename: photo_name, photo_title: value, photo_category: category }).then((response) => {
            let apiData = response.status;
            // console.log(apiData);
        })
    }

    const getFileName = (name) => {
        axios.get(process.env.REACT_APP_API_URL + `/api/get-photo-name.php?filename=${name}`)
            .then((response) => {
                let apiData = response.data;
                // console.log(apiData);
                // console.log(response);
                setCustomName(apiData);
            });
    }

    // function getBack() {
    //     if (category == "uncategorized") {
    //         window.location.replace(`/uncategorized`);
    //     } else {
    //         window.location.replace(`/website/all`);
    //     }
    // }

    const handleSubmit = event => {
        event.preventDefault();
        changeName(name);
        setName('');
        //setTimeout(getBack, 1000);
        document.getElementById('name').blur();
    };

    useEffect(() => {
        props.showHeaderTrue();
        getPhotos();
        getFileName(photo_name);

        const items = (localStorage.getItem('items'));
        if (items) {
            setItems(items);
        } else {
            navigate("/");
        }

    }, []);

    return (
        <>
            <div className="container-u50 one-photo-container">
                <div className="top-bar">
                    <div className="title onephoto-title">
                        Uncategorized Photos
                        <div className="number">
                            ({useParams().number})
                        </div>
                    </div>
                    <div className="controllers">
                        <button className="controller"
                            // onClick={() => [trashPhoto(changeState), deletenotif(), setTimeout(getBack, 2000)]}
                            onClick={() => [trashPhoto(changeState, "trash")]}
                        >
                            <img src={deleteicon} />
                            Delete
                        </button>
                        <ToastContainer
                            position="bottom-right"
                            autoClose={5000}
                            hideProgressBar={true}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                        <button className="controller"
                            // onClick={() => [archivedPhoto(changeState), notify, setTimeout(getBack, 2000)]}
                            onClick={() => [trashPhoto(changeState, "archived")]}
                        >
                            <img src={archive}></img>
                            Archive
                        </button>
                        <ToastContainer
                            position="bottom-right"
                            autoClose={5000}
                            hideProgressBar={true}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    </div>
                </div>
                <div className="photo-viewer">
                    <img src={changeState}>
                    </img>
                </div>
                <div className="photo-name-container">
                    <form className="photo-form" onSubmit={handleSubmit}>
                        <label className="archive-label">
                            Rename photo
                        </label>
                        <div className="rename-photo">
                            <input
                                type="text"
                                id="name"
                                placeholder={customName}
                                className="photo-name-input"
                                onChange={event => (event.keyCode === 13) ? console.log("name") : setName(event.target.value)}
                            />
                            <img className="edit-logo-new" src={edit} />
                            <button type="submit" className="save-name-photo-btn">Save</button>
                        </div>


                    </form>
                </div>

            </div>
        </>
    );
}