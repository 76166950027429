import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
export default function Login(props) {
    const [email, setEmail] = useState("");

    const [password, setPassword] = useState("");

    const [error, setError] = useState("");

    const error1 = "Please insert and an email and a password.";
    const error2 = "Email is invalid.";
    const error3 = "Password is required.";
    const error4 = "Password should be at least 6 characters.";
    const error5 = "Login failed. Try again.";
    const error6 = "Email is required.";

    function handleSubmit(event) {
        event.preventDefault();
    }

    const navigate = useNavigate();

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const [Authenticated, setAuthenticated] = useState(false);

    const login = () => {

        if (email.length == 0 && password.length == 0) {
            setError(error1);
        } else if (password != "" && email == "") {
            setError(error6);
        } else if (!isValidEmail(email)) {
            setError(error2);
        } else if (password == "") {
            setError(error3);
        } else if (password.length <= 5) {
            setError(error4);
            setPassword("");
        } else {
            axios.post(process.env.REACT_APP_API_URL + '/api/login.php', {
                email: email,
                password: password
            }).then((response) => {
                    let apiData = response.data;
                    // console.log(apiData);
                    // let apiStatus = response.status;
                    if (apiData != false) {
                        navigate("/uncategorized");
                        // console.log(item_value);
                        setAuthenticated(apiData);
                        localStorage.setItem('items', apiData);
                        // console.log  (localStorage);/

                    } else {
                        setError(error5);
                        setPassword("");
                    }
                });
        }
    }

    useEffect(() => {
        props.showHeaderFalse();
        document.title = 'McCarthy Family Photos';
        
    });

    return (
        <div className="container">
            <div className="login-wrapper" >
                <div className="Login">
                    <div className="login-title">
                        Log In
                    </div>
                    <div >
                        {/* onSubmit={login}  method="post" id="submitForm" action='#' */}
                        <div className="input-wrapper">
                            <label className="label">Email address</label>
                            <input
                                autoFocus
                                type="email"
                                value={email}
                                placeholder="Enter email"
                                onClick={() => setError("")}
                                onChange={(e) => [setEmail(e.target.value), setError("")]}
                            />
                        </div>
                        <div className="input-wrapper">
                            <label className="label">Password</label>
                            <input
                                type="password"
                                value={password}
                                minLength="6"
                                required
                                placeholder="Enter password"
                                onClick={() => setError("")}
                                onChange={(e) => [setPassword(e.target.value), , setError("")]}
                            />
                        </div>
                        <div className="button-wrapper">
                            <button
                                className="sumbit-button"
                                onClick={login}
                            > Continue </button>
                        </div>

                    </div>
                    <div>
                        <div className="login-warning">
                            {error}
                        </div>

                    </div>
                </div>
            </div>

        </div>

    );
}