import React, { useEffect, useState } from "react";
import axios from 'axios';
import "./Archived.css";
import { Link, useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AlbumPage from "../Website/AlbumPage";


export default function Archived(props) {


  const [photos, setPhotos] = useState([]);

  const [number, setNumber] = useState(0);

  const [photo, setPhoto] = useState(true);

  const navigate = useNavigate();

  const [items, setItems] = useState([]);

  const changeState = (name) => {
    setSrc(process.env.REACT_APP_API_URL + "/photos/" + name);
  };

  function getPhotos() {
    axios.get(process.env.REACT_APP_API_URL + '/api/get-photos.php?type=archived').then((response) => {
      let apiData = response.data;
      if (apiData != null) {
        setPhotos(apiData);
        setNumber(apiData.length);
        setPhoto(true);
      } else {
        setPhoto(false)
      }

    })
  }

  const [src, setSrc] = useState([]);

  useEffect(() => {
    getPhotos();
    props.showHeaderTrue();

    const items = (localStorage.getItem('items'));
    if (items) {
      setItems(items);
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div>
      
        <div className="container-u">
          <div className="top-bar">
            <div className="title">
              Archived Photos
              <div className="number">
                ({number})
              </div>
            </div>
          </div>
          {photo === true ? (
          <div className="photo-collection">
            {photos.map((el, key) => {
              return (
                <Link
                  to={`/archived/${number}/${el.filename}`}
                  key={el.filename} >
                  <div key={'archived-' + key} className="photo-container"
                    onClick={() => [
                      // changeState(el), 
                      setSrc(process.env.REACT_APP_API_URL + "/photos/" + el.filename),
                      setPhoto(true)
                    ]}>
                    <LazyLoadImage
                      delayMethod="throttle"
                      className="fam-memb-img"
                      src={process.env.REACT_APP_API_URL + "/photos/" + el.filename} />
                    <div className="photo-name">
                      {el.photo_title.length > 30 ? `${el.photo_title.substring(0, 25)}...` : el.photo_title}
                    </div>
                  </div>
                </Link>
              );
            })}
          </div> 
       ) : (
        <div className="">
          <AlbumPage />
        </div>
      )}
        </div>

    </div>
  );
}