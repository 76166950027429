import React, { useEffect, useState } from "react";
import axios from 'axios';
import "./Uncategorized";
import blank from '../../assets/images/family-photos/gray.jpg';
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function GenerationsMenu() {

  const navigate = useNavigate();

  const [items, setItems] = useState([]);

  const [firstGen, setFirstGen] = useState([]);

  const [secondGen, setSecondGen] = useState([]);

  const [thirdGen, setThirdGen] = useState([]);

  const idAlbum = useParams().id_album;

  const photo_name = useParams().id;

  let nr_photo = useParams().number;

  const albumName = useParams().album;

  const [photos, setPhotos] = useState([]);

  const notify = (a, b) => toast(<Notif name={a} photoName={b} />);

  const category = window.location.pathname.split('/')[1];

  function Notif(props) {
    return <div>Moved to <strong>{props.name} </strong> <br />{props.photoName.length > 30 ? `${props.photoName.substring(0, 25)}...` : props.photoName}</div>;
  }

  function filenameTrim(value) {
    if (value != "") {
      let result = value.replace('https://apiphotosapp62bab4473780b.cloud.bunnyroute.com/photos/', '');
      return result
    }
  }

  const changeState = 'https://apiphotosapp62bab4473780b.cloud.bunnyroute.com/photos/' + useParams().id;

  function getAlbums() {
    axios.get(process.env.REACT_APP_API_URL + '/api/get-albums.php').then((response) => {
      if (Object.keys(response.data).length > 0) {
        let apiData = response.data;
        const firstGeneration = apiData.filter(album => {
          return album.gen_album === 'first';
        })

        const secondGeneration = apiData.filter(album => {
          return album.gen_album === 'second';
        })

        const thirdGeneration = apiData.filter(album => {
          return album.gen_album === 'third';
        })
        setFirstGen(firstGeneration);
        setSecondGen(secondGeneration);
        setThirdGen(thirdGeneration);
      }
    })
  }

  function getPhotos() {
    // console.log(idAlbum == 'null');
    if (albumName == 'All website photos') {
      axios.get(process.env.REACT_APP_API_URL + `/api/get-photos.php?type=${category}`).then((response) => {
        let apiData = response.data;
        setPhotos(apiData);
      })
    } else {
      axios.get(process.env.REACT_APP_API_URL + `/api/get-photos.php?type=${category}&id_album=${idAlbum == undefined ? "" : idAlbum}`).then((response) => {
        let apiData = response.data;
        setPhotos(apiData);
      })
    }
  }

  function albumPhoto(value, id, nameAlb) {
    let name = filenameTrim(value);
    // setNotifAlbum(nameAlb);
    let album = idAlbum;
    let index_current_photo = photos.findIndex(elem => {
      return elem.filename === photo_name
    });
    if (category == "uncategorized") {
      if (photos.length > 1) {
        if (index_current_photo + 1 < nr_photo) {
          // console.log(photos.indexOf(photo_name) + 1)
          axios.post(process.env.REACT_APP_API_URL + '/api/move-photo.php', { filename: name, photo_title: name, photo_category: "website", id_album: id, from: category }).then((response) => {
            let apiData = response.status;
            if (apiData == 200) {
              getAlbums();
              notify(nameAlb, response.data);
              setTimeout(function () {
                window.location.replace(`/${category}/${nr_photo - 1}/${photos[index_current_photo + 1].filename}`);
              }, 2000);
              // console.log(photo_name);
              // console.log(idAlbum);
              // console.log(nameAlb);         
            }
          })
        } else {
          axios.post(process.env.REACT_APP_API_URL + '/api/move-photo.php', { filename: name, photo_title: name, photo_category: "website", id_album: id, from: category }).then((response) => {
            let apiData = response.status;

            if (apiData == 200) {
              getAlbums();
              notify(nameAlb, response.data);
              setTimeout(function () {
                window.location.replace(`/${category}/${nr_photo - 1}/${photos[index_current_photo - 1].filename}`);
              }, 2000);
              // setTimeout(, 2000);///
              // console.log(photo_name);
              // console.log(idAlbum);
              // setNotifAlbum(nameAlb);
              // console.log(nameAlb);
              // notify(nameAlb);
            }
          })
        }
      } else {
        axios.post(process.env.REACT_APP_API_URL + '/api/move-photo.php', { filename: name, photo_title: name, photo_category: "website", id_album: id, from: category }).then((response) => {
          let apiData = response.status;

          if (apiData == 200) {
            getAlbums();
            notify(nameAlb, response.data);
            setTimeout(function () {
              window.location.replace(`/${category}`);
            }, 2000);
          }
        })
      }
    } else if (category == "website") {

      if (photos.length > 1) {
        nr_photo = photos.length;
        // console.log("aici website");
        // console.log(photos);
        const index_current_photo = photos.findIndex((el) => {
          return el.filename == photo_name;
        })
        if (index_current_photo + 1 < nr_photo) {
          console.log(photos.indexOf(photo_name) + 1)
          axios.post(process.env.REACT_APP_API_URL + '/api/move-photo.php', { filename: name, photo_title: name, photo_category: "website", id_album: id, from: category }).then((response) => {
            let apiData = response.status;
            let apiInfo = response.data;
            if (apiData == 200) {
              // console.log(apiInfo);
              getAlbums();;
              notify(nameAlb, response.data);
              setTimeout(function () {
                window.location.replace(`/website/${albumName}/${album}/${photos[index_current_photo + 1].filename}`);
              }, 2000);
            }
          })

        } else {

          axios.post(process.env.REACT_APP_API_URL + '/api/move-photo.php', { filename: name, photo_title: name, photo_category: "website", id_album: id, from: category }).then((response) => {
            let apiData = response.status;
            if (apiData == 200) {
              getAlbums();
              let apiNr = idAlbum;
              axios.get(process.env.REACT_APP_API_URL + `/api/get-album.php?filename=${photos[0].filename}`)
                .then((response) => {
                  apiNr = response.data[0];
                  notify(nameAlb, response.data);
                  setTimeout(function () {
                    window.location.replace(`/website/${albumName}/${apiNr}/${photos[0].filename}`);
                  }, 2000);
                })
            }
          })
        }
      } else if (photos.length == 1) {
        axios.post(process.env.REACT_APP_API_URL + '/api/move-photo.php', { filename: name, photo_title: name, photo_category: "website", id_album: id, from: category }).then((response) => {
          let apiData = response.status;
          if (apiData == 200) {
            getAlbums();
            getPhotos();
            notify(nameAlb, response.data);
            setTimeout(function () {
              window.location.replace(`/website/all`);
            }, 2000);
          }
        })
      }
    }
  }

  useEffect(() => {
    getAlbums();
    getPhotos();
    const items = (localStorage.getItem('items'));
    if (items) {
      setItems(items);
    } else {
      navigate("/");
    };

  }, []);

  return (
    <div className="container-u50 generation-container">
      <div className="generation">
        First Generation
        <div className="buttons-container">
          {firstGen.map((album, key) => {

            return (
              <button key={'gen1-' + key} className={`blue-button ${album.id_album == idAlbum ? 'markedAlbum' : ''}`}
                onClick={() => albumPhoto(changeState, album.id_album, album.name_album)}>
                <img src={`https://apiphotosapp62bab4473780b.cloud.bunnyroute.com/albums/` + album.id_album + `.png`} />
                {album.name_album}
              </button>
            )
          })}
        </div>
      </div>
      <div className="generation">
        Second generation
        <div className="buttons-container">
          {secondGen.map((album, key) => {
            return (
              <button key={'gen2-' + key} className={`blue-button ${album.id_album == idAlbum ? 'markedAlbum' : ''}`}
                onClick={() => [albumPhoto(changeState, album.id_album, album.name_album)]}>
                <img src={`https://apiphotosapp62bab4473780b.cloud.bunnyroute.com/albums/` + album.id_album + `.png`} />
                {album.name_album}
              </button>
            )
          }
          )}
        </div>
      </div>
      <div className="generation">
        Third generation
        <div className="buttons-container">
          {thirdGen.map((album, key) => {
            return (
              <button key={'gen3-' + key} className={`blue-button ${album.id_album == idAlbum ? 'markedAlbum' : ''}`}
                onClick={() => albumPhoto(changeState, album.id_album, album.name_album)}>
                <img src={`https://apiphotosapp62bab4473780b.cloud.bunnyroute.com/albums/` + album.id_album + `.png`} />
                {album.name_album}
              </button>
            )
          })}
        </div>
      </div>
    </div>
  );
}