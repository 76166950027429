import React, { useEffect, useState } from "react";
import "./Header.css";
import logo_deer from '../../assets/logos/logo-deer.png';
import mcCarthy from '../../assets/logos/McCarthy.png';
import mcCarthySVG from '../../assets/svg/McCarthy.svg';
import { Link, useLocation } from "react-router-dom";

export default function Header() {

  const [tabActive, setTabActive] = useState("uncategorized");
  const handleClick = (event) => {
    setTabActive(event.target.id);

  }

  const location = useLocation();

  const [nav1, setNav1] = useState(false);

  const [nav2, setNav2] = useState(false);

  const [nav3, setNav3] = useState(false);

  const [nav4, setNav4] = useState(false);


  useEffect(() => {
    setTabActive(window.location.pathname.split('/')[1]);
  }, [tabActive])

  return (
    <>
      <div className="header">
        <img src={logo_deer} style={{ marginRight: "17px" }}>
        </img>
        <img src={mcCarthySVG} />
        {/* <div className="header-text">
                McCarthy
              </div> */}
      </div>
      <div className="nav-files">

        <div className="elements-container">
          <Link to="/uncategorized">
            <button className={`nav-element  nav1 ${nav1 ? 'nav1' : ''}`} id="uncategorized" active={tabActive === "uncategorized" ? "yes" : "no"} onClick={handleClick}>
              uncategorized
            </button>
          </Link>
          <Link to="/website/all">
            <button className={`nav-element nav2 ${nav2 ? 'nav2' : ''}`} id="website" active={tabActive === "website" ? "yes" : "no"} onClick={handleClick}>
              Website
            </button>
          </Link>

          <Link to="/archived">
          <button className={`nav-element nav3 ${nav3 ? 'nav3' : ''}`} id="archived" active={tabActive === "archived" ? "yes" : "no"} onClick={handleClick}>
            Archived
          </button>
          </Link>

          <Link to="/trash">
          <button className={`nav-element nav4 ${nav4 ? 'nav4' : ''}`} id="trash" active={tabActive === "trash" ? "yes" : "no"} onClick={handleClick}>
            trash
          </button>
          </Link>

        </div>

      </div>
    </>

  );
}