import './App.css';
import { Routes, Route, Switch, Navigate } from 'react-router-dom';
import { BrowserRouter as Router } from "react-router-dom";
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard'
import Uncategorized from './components/Uncategorized/Uncategorized';
import Archived from './components/Archived/Archived';
import Website from './components/Website/Website';
import Header from './components/Header/Header';
import Trash from './components/Trash/Trash';
import { useState } from 'react';
import OnePhoto from './components/Uncategorized/OnePhoto';
import GenerationsMenu from './components/Uncategorized/GenerationsMenu';
import PhotoTitle from './components/Website/PhotoTitle';
import OneArchivedPhoto from './components/Archived/OneArchivedPhoto';
import axios from 'axios';
import { useEffect } from 'react';

function App() {

  const [Authenticated, setAuthenticated] = useState(false);

  const [showHeader, setShowHeader] = useState(false);

  const setLoginTrue = () => {
    setAuthenticated(true);
  }

  const showHeaderTrue = () => {
    setShowHeader(true);
  }

  const showHeaderFalse = () => {
    setShowHeader(false);
  }

  useEffect(() => {
    document.title = 'McCarthy Family Photos';
    
});


  return (
    <div className="App">
      <Router>
        {showHeader && <Header />}

        <Routes>

          <Route
            path="/auth"
            element={<Login setLoginTrue={setLoginTrue}  showHeaderFalse={showHeaderFalse}/>}
          />
          <Route
            path="/"
            element={<Login setLoginTrue={setLoginTrue} showHeaderFalse={showHeaderFalse}/>}
          />
          <Route
            exact
            path="/login"
            element={
              <Login showHeaderFalse={showHeaderFalse} />
            }
          />

          <Route
            exact
            path="/"
            element={
              <Login showHeaderFalse={showHeaderFalse} />
            }
          />
        
          <Route
            exact
            path="/uncategorized"
            element={
              <Uncategorized showHeaderTrue={showHeaderTrue} />
            }
          />

            <Route
              exact
              path="/uncategorized/:number/:id"
              element={
                // <Uncategorized showHeaderTrue={showHeaderTrue} />
                <div className="container-u2">
                <OnePhoto showHeaderTrue={showHeaderTrue}/>
                <GenerationsMenu/>
                </div>
              }
            />

          <Route
            exact
            path="/website/:album"
            element={
              <Website showHeaderTrue={showHeaderTrue} />
            }
          />

          <Route
            exact
            path="/website/:album/:id_album"
            element={
              <Website showHeaderTrue={showHeaderTrue} />
            }
          />  

            <Route
              exact
              path="/website/:album/:id_album/:id"
              element={
                <div className="container-u2">
                <PhotoTitle/>
                <OnePhoto showHeaderTrue={showHeaderTrue}/>
                <GenerationsMenu/>
                </div>
              }
            />

          <Route    
            exact
            path="/archived"
            element={
              <Archived showHeaderTrue={showHeaderTrue} />
            }
          />

        <Route
            exact
            path="/archived/:number/:id"
            element={
              <OneArchivedPhoto showHeaderTrue={showHeaderTrue} />
            }
          />

          <Route
            exact
            path="/trash"
            element={
              <Trash showHeaderTrue={showHeaderTrue} />
            }
          />

        <Route
            exact
            path="/trash/:number/:id"
            element={
              <OneArchivedPhoto showHeaderTrue={showHeaderTrue} />
            }
          />


        </Routes>

        
      </Router>

    </div>
  );
}

export default App;


// 1440 X 920