import React, { useEffect, useState } from "react";
import axios from 'axios';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import "./Uncategorized.css";

import { Navigate, useNavigate, Link } from "react-router-dom";
import AlbumPage from "../Website/AlbumPage";


export default function Uncategorized(props) {

  // fetch('login.php', {credentials: "same-origin"})
  // .then(response => response.json())
  // .then(data => console.log(data));

  const navigate = useNavigate();

  const [items, setItems] = useState([]);

  const [nr, setNr] = useState([]);

  const [photo, setPhoto] = useState(true);

  const [photos, setPhotos] = useState([]);

  function getPhotos() {
    axios.get(process.env.REACT_APP_API_URL + '/api/get-photos.php?type=uncategorized').then((response) => {
      let apiData = response.data;
      if (apiData != null) {
        setPhotos(apiData);
        setNr(apiData.length);
      } else {
        setPhoto(false);
      }
    })
  }

  useEffect(() => {
    props.showHeaderTrue();
    getPhotos();
    const items = (localStorage.getItem('items'));
    if (items) {
      setItems(items);
    } else {
      navigate("/");
    }

    // console.log(items);
  }, []);

  return (
    <div>
      {photo === true ? (
        <div className="container-u">
          <div className="top-bar">
            <div className="title">
              Uncategorized Photos
              <div className="number">
                ({photos.length})
              </div>
            </div>
          </div>

          <div className="photo-collection" >
            {photos.map((name, key) => {
              return (
                <Link
                  to={`/uncategorized/${nr}/${name.filename}`}
                  key={name}

                >
                  <div key={'uncategorized' + key} className="photo-container"
                  >

                    <LazyLoadImage
                      delayMethod="throttle"
                      // threshold
                      className="fam-memb-img"
                      src={process.env.REACT_APP_API_URL + "/photos/" + name.filename} // use normal <img> attributes as props
                    />
                    {/* <img className="fam-memb-img" src={process.env.REACT_APP_API_URL + "/photos/" + name} /> */}
                    <div className="photo-name">
                      {/* {name.substring(0, 27)} */}
                      {name.photo_title.length > 30 ? `${name.photo_title.substring(0, 25)}...` : name.photo_title}
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="container-u2">
          <AlbumPage />
        </div>
      )}
    </div>
  );
}