import React, { useEffect, useState } from "react";
import axios from 'axios';
import "./Trash.css";

import { LazyLoadImage } from 'react-lazy-load-image-component';

import trashcan from '../../assets/svg/delete.svg'
import { Link, useNavigate } from "react-router-dom";
import AlbumPage from "../Website/AlbumPage";


export default function Trash(props) {

    const navigate = useNavigate();

    const [items, setItems] = useState([]);

    const [src, setSrc] = useState([]);

    const [number, setNumber] = useState();

    const [photos, setPhotos] = useState([]);

    const [photo, setPhoto] = useState(true);

    function getPhotos() {
        axios.get(process.env.REACT_APP_API_URL + '/api/get-photos.php?type=trash').then((response) => {
            let apiData = response.data;
            if (apiData != null) {
                setPhotos(apiData);
                setNumber(apiData.length);
                setPhoto(true);
            } else {
                setPhoto(false)
            }
        })
    }

    function deletePhotos() {
        axios.delete(process.env.REACT_APP_API_URL + '/api/trash-photos.php').then((response) => {
            let apiData = response.data;
            if (response.status == 200) {
                // console.log(apiData);
                getPhotos(apiData);

            }
        })

    }

    useEffect(() => {
        getPhotos();
        props.showHeaderTrue();

        deletePhotos();
        const items = (localStorage.getItem('items'));
        if (items) {
            setItems(items);
        } else {
            navigate("/");
        }
    }, []);

    return (
        <div>
            
                <div className="container-u">
                    <div className="top-bar">
                        <div className="title">
                            Trash
                            <div className="number">
                                ({photos.length})
                            </div>
                        </div>

                        <div className="trash-info">
                            Photos in the Trash folder will be permanently deleted after 30 days
                            <img src={trashcan}>
                            </img>
                        </div>
                    </div>
                    {photo === true ? (
                    <div className="photo-collection">
                        {photos.map((el, key) => {
                            return (
                                <Link
                                    to={`/trash/${number}/${el.filename}`}
                                    key={el.filename} >

                                    <div className="photo-container"
                                        onClick={() => [
                                            setSrc(process.env.REACT_APP_API_URL + "/photos/" + el.filename),
                                            setPhoto(true)
                                        ]}>
                                        <LazyLoadImage
                                            delayMethod="throttle"
                                            className="fam-memb-img"
                                            src={process.env.REACT_APP_API_URL + "/photos/" + el.filename} />
                                        <div className="photo-name">
                                            {el.photo_title.length > 30 ? `${el.photo_title.substring(0, 25)}...` : el.photo_title}
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                    ) : (
                        <div className="">
                            <AlbumPage />
                        </div>
                    )}
                </div>
            
        </div>
    );
}