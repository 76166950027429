import React, { useEffect, useState } from "react";
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import 'bootstrap/dist/css/bootstrap.min.css';

import { Menu, MenuItem, MenuButton, SubMenu, MenuDivider } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/core.css';


import OnePhoto from "../Uncategorized/OnePhoto";
import GenerationsMenu from "../Uncategorized/GenerationsMenu";
import PhotoTitle from "./PhotoTitle";

import "./Website.css";
import 'react-dropdown/style.css';

import AlbumPage from "./AlbumPage";
import arrow from '../../assets/icons/arrow.png';
import Header from "../Header/Header";
import { Link, useNavigate } from "react-router-dom";

export default function Website(props) {

    const navigate = useNavigate();

    const [items, setItems] = useState([]);

    const [photos, setPhotos] = useState([]);

    const [firstGen, setFirstGen] = useState([]);

    const [firstGen1, setFirstGen1] = useState([]);

    const [secondGen, setSecondGen] = useState([]);

    const [noResult, setNoResult] = useState(false);

    const [albumLenght, setAlbumLenght] = useState(0);

    const [albumName, setAlbumName] = useState();

    const [searchAlbum, setSearchAlbum] = useState();

    const [albumId, setAlbumId] = useState();

    const [src, setSrc] = useState([]);

    const changeState = (name) => {
        setSrc(process.env.REACT_APP_API_URL + "/photos/" + name);
    };

    const [clicked, setClicked] = useState(false);

    const [photo, setPhoto] = useState(true);

    function getPhotos() {
        axios.get(process.env.REACT_APP_API_URL + '/api/get-photos.php?type=website').then((response) => {
            let apiData = response.data;
            if (apiData != null) {
                setPhotos(apiData);
                setAlbumLenght(apiData.length);
                setAlbumName('All website photos');
                setSearchAlbum('Select an album');
            } else {
                setPhoto(false);
            }
        })
    }

    function getAlbums() {
        axios.get(process.env.REACT_APP_API_URL + '/api/get-albums.php').then((response) => {
            if (Object.keys(response.data).length > 0) {
                let apiData = response.data;
                const firstGeneration = apiData.filter(album => {
                    return album.gen_album === 'first';
                })
                const secondGeneration = apiData.filter(album => {
                    return album.gen_album === 'second';
                })
                setFirstGen(firstGeneration);
                setFirstGen1(firstGeneration);
                setSecondGen(secondGeneration);
            }
        })
    }
    function changeAlbum(value) {
        if (value != -1) {
            axios.get(process.env.REACT_APP_API_URL + '/api/get-photos.php?type=website' + '&id_album=' + value).then((response) => {
                let apiData = response.data;
                // console.log(apiData);
                if (apiData != null) {
                    setPhotos(apiData);
                    setNoResult(false);
                    setAlbumLenght(apiData.length);
                } else {
                    setNoResult(true);
                    setAlbumLenght(0);
                }
            })

        } else {
            axios.get(process.env.REACT_APP_API_URL + '/api/get-photos.php?type=website').then((response) => {
                let apiData = response.data;
                if (apiData.length > 0) {
                    setPhotos(apiData);
                    setNoResult(false);
                    setAlbumLenght(apiData.length);
                    setAlbumName("All website photos");
                    setSearchAlbum('Select an album');
                }
            })
        }
    }

    useEffect(() => {
        getAlbums();
        getPhotos();
        props.showHeaderTrue();
        const items = (localStorage.getItem('items'));
        if (items) {
            setItems(items);
        } else {
            navigate("/");
        }
    }, []);
    var gen1 = [];
    firstGen.map((element, key, index) => {
        gen1.push({ value: key, label: element.name_album, className: 'myOptionClassName' });
    });

    var gen2 = [];
    secondGen.map((element, key, index) => {
        gen2.push({ value: key, label: element.name_album, className: 'myOptionClassName' });
    });
    return (
        <div>
            {photo === true ? (
                <div className="container-u">
                    <div className="top-bar-website">
                        <div className="title">
                            {albumName}
                            <div className="number">
                                ({albumLenght})
                            </div>
                        </div>
                        <img className={`arrow ${clicked == true ? 'new-arrow' : ''}`} src={arrow} />
                        <Menu className="left-position" menuButton={<MenuButton className="dropdown-styling" onClick={() => clicked == false ? setClicked(true) : setClicked(false)}>{searchAlbum}</MenuButton>}>
                            <Link to={`/website/all`}>
                                <MenuItem key="all" className="top-option" onClick={() => [changeAlbum(-1), setClicked(false)]}>
                                    View all website photos
                                </MenuItem>
                            </Link>
                            <MenuDivider />
                            <div className="section-title"> Generation 1 </div>
                            {firstGen.map((el, index) =>
                                <div key={index} >
                                    <Link key={el.id_album} to={`/website/${el.name_album}/${el.id_album}`}>
                                        <MenuItem key={el.id_album} className="myOptionClassName"
                                            onClick={() => [
                                                changeAlbum(el.id_album),
                                                setAlbumName(el.name_album),
                                                setSearchAlbum(el.name_album),
                                                setClicked(false),
                                            ]}
                                        >
                                            {el.name_album}
                                        </MenuItem>
                                    </Link>
                                </div>
                            )}
                            <MenuDivider />
                            <div className="section-title"> Generation 2 </div>
                            {secondGen.map(el =>
                                <>
                                    <Link to={`/website/${el.name_album}/${el.id_album}`}>
                                        <MenuItem key={el.id_album} className="myOptionClassName"
                                            onClick={() => [
                                                changeAlbum(el.id_album),
                                                setAlbumName(el.name_album,
                                                setSearchAlbum(el.name_album)),
                                                // console.log("here"),
                                                setClicked(false)]}>
                                            {el.name_album}
                                        </MenuItem>
                                    </Link>
                                </>
                            )}
                        </Menu>
                    </div>
                    {noResult === false ? (
                        <div className="photo-collection">
                            {photos.map((el, key) => {
                                return (
                                    <Link
                                        to={`/website/${albumName}/${el.id_album}/${el.filename}`}
                                        key={el.name} >
                                        <div key={'website-' + key} className="photo-container"
                                            onClick={() => [
                                                changeState(el),
                                                setAlbumId(el.id_album),
                                                setSrc(process.env.REACT_APP_API_URL + "/photos/" + el.filename),
                                            ]}>
                                            <LazyLoadImage
                                                delayMethod="throttle"
                                                className="fam-memb-img gen1"
                                                src={process.env.REACT_APP_API_URL + "/photos/" + el.filename} />
                                            <div className="photo-name">
                                                {el.photo_title.length > 30 ? `${el.photo_title.substring(0, 25)}...` : el.photo_title}
                                            </div>
                                        </div>
                                    </Link>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="container-u2">
                            <AlbumPage />
                        </div>
                    )}
                </div>
            ) : (
                <div className="container-u2">
                    <AlbumPage />
                </div>
            )}
        </div>
    );
}