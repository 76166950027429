import React, { useEffect, useState } from "react";

export default function AlbumPage() {
    return (
        <div className="error-container">
            <div className="error-text">
                There are no results.
            </div>
        </div>

    );
}