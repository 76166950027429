import React, { useState } from "react";
import "./Website.css";
import { useParams } from "react-router-dom";
export default function PhotoTitle(props) {
    return (
        <div className="container-u50 one-photo-container absolute">
            <div className="top-bar">
                <div className="title onephoto-title">
                {useParams().album}
                </div>
            </div>
        </div>

    );
}