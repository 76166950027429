import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Navigate, useNavigate, Link } from "react-router-dom";

import restore from '../../assets/icons/restore.png';
import edit from '../../assets/icons/edit-icon.png';
import { useParams } from "react-router-dom";
import axios from "axios";

import { CSSProperties } from "react";
import FadeLoader from "react-spinners/FadeLoader";


export default function OneArchivedPhoto(props) {
    const navigate = useNavigate();

    const [items, setItems] = useState([]);

    const [albumName, setAlbumName] = useState();

    const deletenotif = () => toast("Undeleted - moved to Uncategorized");

    const notify = () => toast("Unarchived to Uncategorized");

    const category = window.location.pathname.split('/')[1];


    function currentAlbum() {
        if (category == "trash") {
            setAlbumName("Trash");
        } else {
            setAlbumName("Archived Photos");
        }
    }

    const [photo, setPhoto] = useState();

    const [customName, setCustomName] = useState();

    const [info, setInfo] = useState([]);

    const photoName = useParams().id;

    const [name, setName] = useState(photoName);

    const nr = useParams().number;

    const changeState = 'https://apiphotosapp62bab4473780b.cloud.bunnyroute.com/photos/' + useParams().id;

    function getBack() {
        if (category == "trash") {
            window.location.replace(`/trash`);
        } else {
            window.location.replace(`/archived`);
        }
    }
    function filenameTrim(value) {
        if (value != "") {
            let result = value.replace('https://apiphotosapp62bab4473780b.cloud.bunnyroute.com/photos/', '');
            return result
        }
    }

    function restorePhoto(value) {
        let name = filenameTrim(value);
        axios.post(process.env.REACT_APP_API_URL + '/api/restore-photo.php', { filename: name, photo_title: name, photo_category: "uncategorized" }).then((response) => {
            let apiData = response.status;
        })
    }

    function changeName(value) {
        console.log("newname");
        axios.post(process.env.REACT_APP_API_URL + '/api/photo-name.php', { filename: photoName, photo_title: value, photo_category: category }).then((response) => {
            let apiData = response.status;
        })
    }

    const handleSubmit = event => {
        event.preventDefault();
        changeName(name);
        setName('');
        setTimeout(getBack, 1000);
    };

    const getFileName = (name) => {
        axios.get(process.env.REACT_APP_API_URL + `/api/get-photo-name.php?filename=${name}`)
            .then((response) => {
                let apiData = response.data;
                setCustomName(apiData);
            });
    }

    useEffect(() => {
        props.showHeaderTrue();
        currentAlbum();
        getFileName(window.location.pathname.split('/')[3]);

        const items = (localStorage.getItem('items'));
        if (items) {
            setItems(items);
        } else {
            navigate("/");
        }
    }, []);


    return (
        <div className="one-photo-container">
            <div className="top-bar">
                <div className="title onephoto-title">
                    {albumName}
                    <div className="number">
                        ({nr})
                    </div>
                </div>
                <div className="controllers">
                    <button className="controller" onClick={() => [restorePhoto(photoName), category == "trash" ? deletenotif() : notify(), setTimeout(getBack, 2000)]}>
                        <img src={restore}></img>
                        Restore
                    </button>
                    <ToastContainer

                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />

                </div>
            </div>

            <div className="photo-viewer">
                <img className="img-view" src={changeState} />

            </div>


            <div className="display-center">
                <div className="photo-name-container archived-name">

                    <form onSubmit={handleSubmit}>
                        <label className="archive-label">
                            Rename photo
                        </label>
                        <div className="rename-photo">
                            <input
                                type="text"
                                id="name"
                                placeholder={customName}
                                // value={name}
                                className="photo-name-input archived-name"
                                onChange={event => (event.keyCode === 13) ? console.log("name") : setName(event.target.value)}
                            //  onKeyDown={(e) => something(e)}
                            />
                            <button type="submit" className="save-name-photo-btn">Save</button>
                        </div>

                        <img className="edit-logo" src={edit} />

                    </form>

                </div>
            </div>


        </div>

    );
}